<template>
  <div class="mx-1">
    <b-row>
      <ContratosTotalizadores :isBusy="isBusy" />
    </b-row>
    <b-row>
      <b-card class="w-100">
        <ContratosFiltros
          :userData="userData"
          ref="contratoFiltros"
          :isBusy="isBusy"
          @carregarGridHandler="carregarGridHandler"
        />
        <ContratosTabela :userData="userData" ref="contratoTabela" :isBusy="isBusy" />
      </b-card>
    </b-row>
  </div>
</template>
<script>
  import ContratosTotalizadores from '@/views/pages/financeiro/contratos/components/contratosLista/contratosTotalizadores.vue'
  import ContratosFiltros from '@/views/pages/financeiro/contratos/components/contratosLista/contratosFiltros.vue'
  import ContratosTabela from '@/views/pages/financeiro/contratos/components/contratosLista/contratosTabela.vue'
  import ContratosContrato from '@/views/pages/financeiro/contratos/contratosContrato.vue'
  export default {
    components: {
      ContratosTotalizadores,
      ContratosFiltros,
      ContratosTabela,
      ContratosContrato,
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        isBusy: true,
        contratoId: null,
      }
    },
    async mounted() {
      this.isBusy = false
      await this.carregarGridHandler({
        pesquisa: '',
        tipoPesquisa: '',
      })
    },
    methods: {
      carregarGridHandler(filtros) {
        this.$refs.contratoTabela.carregarGrid(filtros)
      },
    },
  }
</script>
