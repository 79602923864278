<template>
  <div>
    <b-row>
      <b-col md="4">
        <h1 class="ml-1">Filtros</h1>
      </b-col>
      <b-col md="8" class="justify-content-end">
        <b-input-group class="w-auto float-right">
          <b-form-input
            id="pesquisarContrato"
            class="border-right-white"
            :placeholder="`Pesquisar ${pesquisar.tipoPesquisa}`"
            v-model="pesquisar.pesquisa"
            @input="filtrar()"
            @keyup.enter="filtrar()"
            :type="pesquisar.tipoPesquisa !== 'matricula' ? 'text' : 'number'"
          />
          <b-input-group-append>
            <span
              class="input-group-text text-primary"
              style="cursor: pointer"
              v-if="!isBusy"
              v-b-tooltip.hover.bottom="'Filtrar'"
              @click="filtrar()"
            >
              <feather-icon icon="SearchIcon" />
            </span>
            <b-button v-else variant="outline-primary" class="border-right-white" disabled>
              <b-spinner small />
            </b-button>
            <v-select-pt
              class="w-custom-input"
              :clearable="false"
              name="tipoPesquisa"
              v-model="pesquisar.tipoPesquisa"
              :options="tipoPesquisa"
              :reduce="(option) => option.id"
              label="name"
            >
            </v-select-pt>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

  export default {
    components: {
      FeatherIcon,
    },
    props: {
      isBusy: {
        type: Boolean,
      },
    },
    data() {
      return {
        pesquisar: {
          pesquisa: '',
          tipoPesquisa: 'nome',
        },
        localIsBusy: this.isBusy,
        tipoPesquisa: [
          { id: 'nome', name: 'Nome', selected: true },
          { id: 'contrato', name: 'N° Contrato' },
          { id: 'matricula', name: 'N° Matricula' },
        ],
      }
    },
    methods: {
      filtrar() {
        this.$emit('carregarGridHandler', this.pesquisar)
      },
    },
  }
</script>
<style>
  .w-custom-input {
    min-width: 7vw;
    max-width: auto;
  }
  #pesquisarContrato {
    width: 20vw !important;
  }
  .vs__dropdown-toggle {
    height: 100% !important;
    border-radius: 0 0.357rem 0.357rem 0 !important;
  }
  #pesquisarContrato:focus,
  #pesquisarContrato:focus-within,
  #pesquisarContrato:focus-visible {
    border-right: 0px !important;
  }
</style>
